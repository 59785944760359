import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
// components
import Livecoverages from "./components/Livecoverages";
import Options from "./components/Options";
import Search from "./components/Search";
// styles
import { GlobalStyle } from "./App.styles";

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Route exact path="/" component={Livecoverages} />
        <Route path="/ustawienia" component={Options} />
        <Route path="/search" component={Search} />
      </Router>
    </>
  );
}

export default App;
