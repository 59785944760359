import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  margin: 5px auto;
  padding: 10px;
  border: 1px solid #393939;
  background-color: #333333;
  max-height: 100%;
  justify-content: space-between;
  font-family: "Lato";

  .canalName {
    vertical-align: center;
    color: #dddddd;
    padding: 0;
    margin: 0;
  }

  .toggle {
    vertical-align: center;
  }

  @media only screen and (min-width: 900px) {
  }
`;

export default Wrapper;
