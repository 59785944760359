import React from "react";
// components
import MenuHeader from "./MenuHeader";
import OptionsEntry from "./OptionsEntry";
// styles
import { Wrapper } from "./Options.styles";

const Options: React.FC = () => {
  const [canalsAll, canalsAllSet] = React.useState([""]);
  const [canalsFiltered, canalsFilteredSet] = React.useState([""]);

  React.useEffect(() => {
    if (localStorage.getItem("allCanals")) {
      const tmpCanalsAll =
        JSON.parse(localStorage.getItem("allCanals") || "") || [];
      canalsAllSet(tmpCanalsAll);
    }
    if (localStorage.getItem("filteredCanals")) {
      const tmpCanalsFiltered =
        JSON.parse(localStorage.getItem("filteredCanals") || "") || [];
      canalsFilteredSet(tmpCanalsFiltered);
    }
  }, []);

  const canalToogle = (canal: string, value: boolean) => {
    let tmpCanals = canalsFiltered;
    if (value) {
      tmpCanals = tmpCanals.concat(canal);
    } else {
      tmpCanals = tmpCanals.filter((item: string) => item !== canal);
    }
    canalsFilteredSet(tmpCanals);
    try {
      localStorage.setItem("filteredCanals", JSON.stringify(tmpCanals));
    } catch (err) {
      console.log(err);
    }
  };

  const renderOptionsEntries = () => {
    return (
      <>
        {canalsAll.map((canal: string) => (
          <OptionsEntry
            key={canal}
            name={canal}
            checkedCanal={canalsFiltered.includes(canal) || false}
            toogle={(value: boolean) => canalToogle(canal, value)}
          />
        ))}
      </>
    );
  };

  return (
    <Wrapper>
      <MenuHeader />
      <div className="optionsScreen">
        <div className="col">
          <div className="list">
            <h1 className="title">Ustawienia</h1>
            {renderOptionsEntries()}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Options;
