import React from "react";
// components
import gamesStore, { GameObject } from "../stores/GamesStore";
import MenuHeader from "./MenuHeader";
import LivecoveragesList from "./LivecoveragesList";
// styles
import { Wrapper } from "./Search.styles";

const Search: React.FC = () => {
  const [query, querySet] = React.useState("");
  const [data, dataSet] = React.useState<GameObject[]>([]);

  React.useEffect(() => {
    gamesStore.searchGames(query);
    dataSet(gamesStore.games.gamesSearched);
  }, [query]);

  return (
    <Wrapper>
      <MenuHeader />
      <div className="searchScreen">
        <div className="col">
          <div className="searchWrapper">
            <input
              className="inputBox"
              placeholder="np. Liverpool FC"
              onChange={(e) => querySet(e.target.value)}
            />
          </div>
          <div className="list">
            <LivecoveragesList data={data} loading={false} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Search;
