import React from "react";
// componets
import { Dots } from "react-activity";
import LivecoverageEntry from "./LivecoverageEntry";
// styles
import { Wrapper } from "./LivecoveragesList.styles";

// types
import { GameObject } from "../stores/GamesStore";

type Props = {
  data: GameObject[];
  loading: Boolean;
};

const LivecoveragesList: React.FC<Props> = ({ data, loading }: Props) => {
  const renderLoadingInfo = () => {
    return (
      <div className="loading">
        <Dots color="#1188FF" />
      </div>
    );
  };

  const renderLivecoverageEntries = () => {
    return (
      <>
        {data.map((item) => (
          <LivecoverageEntry data={item} key={item.id} />
        ))}
      </>
    );
  };

  const renderEmptyInfo = () => {
    return (
      <div className="loading">
        <p className="empty">brak transmisji</p>
      </div>
    );
  };

  const renderLivecoveragesList = () => {
    return (
      <>
        {loading && renderLoadingInfo()}
        {!loading && data.length > 0 && renderLivecoverageEntries()}
        {!loading && data.length === 0 && renderEmptyInfo()}
      </>
    );
  };

  return <Wrapper>{renderLivecoveragesList()}</Wrapper>;
};

export default LivecoveragesList;
