import styled from "styled-components";

export const Wrapper = styled.div`
  @import url("https://fonts.googleapis.com/css?family=Lato");

  display: flex;
  width: 25% - 2px;
  border: 1px solid #393939;
  background-color: #333333;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-direction: column;
  text-align: center;
  font-family: "Lato";

  p {
    margin: 0;
    padding: 0;
    font-size: 0.75em;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .category {
    color: #888888;
  }

  .league {
    color: #aaaaaa;
  }

  .game {
    color: #dddddd;
    font-size: 0.8em;
  }

  .tvdatetime {
    color: #2196f3;
    font-size: 0.7em;
  }

  .tvcanal {
    color: #2196f3;
    font-size: 0.7em;
  }
`;

export default Wrapper;
