import React from "react";
// components
import LivecoveragesList from "./LivecoveragesList";
// styles
import { Wrapper } from "./LivecoveragesCol.styles";
// types
import { GameObject } from "../stores/GamesStore";

type Props = {
  activeElement: string;
  data: GameObject[];
  loading: Boolean;
  name: string;
};

const LivecoveragesCol: React.FC<Props> = ({
  name,
  data,
  activeElement,
  loading,
}: Props) => {
  return (
    <Wrapper activeTab={activeElement === name}>
      <LivecoveragesList data={data} loading={loading} />
    </Wrapper>
  );
};

export default LivecoveragesCol;
