import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: #3c3c3c;

  .title {
    text-align: center;
    color: #aaaaaa;
    font-family: "Lato";
    font-size: 1em;
    text-transform: uppercase;
    margin: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 0;
    background-color: transparent;
  }

  .title:hover {
    cursor: pointer;
  }

  .search {
    position: absolute;
    right: 32px;
    text-align: center;
    color: #aaaaaa;
    font-family: "Lato";
    font-size: 1em;
    text-transform: uppercase;
    margin: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 0;
    background-color: transparent;
  }

  .search:hover {
    cursor: pointer;
  }

  .options {
    position: absolute;
    right: 0;
    text-align: center;
    color: #aaaaaa;
    font-family: "Lato";
    font-size: 1em;
    text-transform: uppercase;
    margin: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 0;
    background-color: transparent;
  }

  .options:hover {
    cursor: pointer;
  }

  .row {
    display: flex;
    width: 100vw;
    height: 30px;
    justify-content: center;
  }

  @media only screen and (min-width: 900px) {
  }
`;

export default Wrapper;
