import { makeAutoObservable, runInAction } from "mobx";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// components
import { CanalsObject } from "./CanalsStore";

dayjs.extend(utc);

export interface GameObject {
  id: number;
  league: string;
  game: string;
  tvdatetime: string;
  tvcanal: string;
  accepted: number;
  category: string;
  source: string;
  yes: string;
  no: string;
}

export interface GamesObject {
  loading: Boolean;
  gamesAll: GameObject[];
  gamesToday: GameObject[];
  gamesTomorrow: GameObject[];
  gamesWeek: GameObject[];
  gamesLater: GameObject[];
  gamesSelectedByCanals: GameObject[];
  gamesSearched: GameObject[];
}

export class GamesStore {
  public games: GamesObject = {
    loading: false,
    gamesAll: [],
    gamesToday: [],
    gamesTomorrow: [],
    gamesWeek: [],
    gamesLater: [],
    gamesSelectedByCanals: [],
    gamesSearched: [],
  };

  private tvcanalsFromLocalstore: CanalsObject[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public loadGames = async () => {
    this.games.loading = true;
    const url: string = process.env.REACT_APP_URL!;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });

    runInAction(() => {
      this.games.gamesAll = resData;

      this.tvcanalsFromLocalstore = this.getTvcanalsFromLocalstorage();

      this.games.gamesSelectedByCanals = this.getGamesSelectedByCanals();

      this.games.gamesToday = this.getGamesToday();
      this.games.gamesTomorrow = this.getGamesTomorrow();
      this.games.gamesWeek = this.getGamesWeek();
      this.games.gamesLater = this.getGamesLater();

      this.games.loading = false;
    });
  };

  public searchGames = (word: string) => {
    this.games.gamesSearched = word.length ? this.searchGamesByWord(word) : [];
  };

  private getTvcanalsFromLocalstorage = () => {
    return localStorage.getItem("filteredCanals") === null
      ? JSON.parse(localStorage.getItem("allCanals") || "") || []
      : JSON.parse(localStorage.getItem("filteredCanals") || "");
  };

  private getGamesSelectedByCanals = () => {
    return this.games.gamesAll.filter((gam) =>
      this.tvcanalsFromLocalstore.some((sel: any) => gam.tvcanal.includes(sel))
    );
  };

  private getGamesToday = () => {
    return this.games.gamesSelectedByCanals.filter(
      (gam: GameObject) =>
        dayjs(gam.tvdatetime).utcOffset(0).format("YYYYMMDD") ===
        dayjs().format("YYYYMMDD")
    );
  };

  private getGamesTomorrow = () => {
    return this.games.gamesSelectedByCanals.filter(
      (gam: GameObject) =>
        dayjs(gam.tvdatetime).utcOffset(0).format("YYYYMMDD") ===
        dayjs().add(1, "days").format("YYYYMMDD")
    );
  };

  private getGamesWeek = () => {
    return this.games.gamesSelectedByCanals.filter(
      (gam: GameObject) =>
        dayjs(gam.tvdatetime).utcOffset(0).format("YYYYMMDD") >=
          dayjs().add(2, "days").format("YYYYMMDD") &&
        dayjs(gam.tvdatetime).utcOffset(0).format("YYYYMMDD") <
          dayjs().add(7, "days").format("YYYYMMDD")
    );
  };

  private getGamesLater = () => {
    return this.games.gamesSelectedByCanals.filter(
      (gam: GameObject) =>
        dayjs(gam.tvdatetime).utcOffset(0).format("YYYYMMDD") >=
        dayjs().add(7, "days").format("YYYYMMDD")
    );
  };

  private searchGamesByWord = (word: string) => {
    return this.games.gamesSelectedByCanals.filter(
      (gam) =>
        gam.category.toLowerCase().includes(word.toLowerCase()) ||
        gam.tvcanal.toLowerCase().includes(word.toLowerCase()) ||
        gam.game.toLowerCase().includes(word.toLowerCase()) ||
        gam.league.toLowerCase().includes(word.toLowerCase())
    );
  };
}

const gamesStore = new GamesStore();

export default gamesStore;
