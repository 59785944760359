import React from "react";
import { OptionsOutline, Search } from "react-ionicons";
import { Link } from "react-router-dom";
// styles
import { Wrapper } from "./MenuHeader.styles";

const MenuHeader: React.FC = () => {
  // @ts-ignore
  return (
    <Wrapper>
      <div className="row header">
        <Link to="/">
          <button type="button" className="title">
            Sport w TV
          </button>
        </Link>
        <Link to="/search">
          <button type="button" className="search">
            <Search color="#aaaaaa" />
          </button>
        </Link>
        <Link to="/ustawienia">
          <button type="button" className="options">
            <OptionsOutline color="#aaaaaa" />
          </button>
        </Link>
      </div>
    </Wrapper>
  );
};

export default MenuHeader;
