import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: #3c3c3c;

  .row {
    display: flex;
  }

  .tabs {
    display: flex;
    width: 100vw;
    height: 50px;
  }

  .data {
    display: flex;
    width: 100vw - 3px;
    height: calc(100vh - 82px);
  }

  @media only screen and (min-width: 900px) {
    .row {
      flex-direction: row;
    }
    .tabs {
      height: 50px;
    }
    .data {
      display: flex;
      width: 100vw - 3px;
      height: calc(100vh - 82px);
    }
  }
`;

export default Wrapper;
