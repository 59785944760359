import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 0;
  margin: 0 auto;
  flex-direction: column;
  background-color: #3c3c3c;

  .searchScreen {
    display: flex;
    height: calc(100vh - 32px);
    justify-content: center;
  }

  .col {
    display: flex;
    max-height: 100%;
    flex-direction: column;
  }

  .searchWrapper {
    display: block;
    width: 100vw;
    height: 40px;
    justify-content: center;
    text-align: center;
  }

  .inputBox {
    width: 70%;
    text-align: center;
    height: 25px;
    padding: 2px;
    margin: 2px;
    background-color: #333333;
    border: 1px solid #393939;
    color: #dddddd;
  }

  .inputBox:focus,
  .inputBox:focus {
    outline: none;
  }

  .list {
    overflow: auto;
    display: block;
    width: 100vw;
    /* width */
    ::-webkit-scrollbar {
      width: 1px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888888;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #1188ff;
    }
  }

  .title {
    color: #dddddd;
    font-size: 1em;
    font-family: "Lato";
  }

  @media only screen and (min-width: 900px) {
    display: flex;
    text-align: center;
    padding: 0;
    margin: 0 auto;

    .searchScreen {
      display: flex;
      height: calc(100vh - 32px);
      justify-content: center;
    }

    .col {
      display: flex;
      max-height: 100%;
    }

    .searchWrapper {
      display: block;
      width: 50vw;
      height: 40px;
      justify-content: center;
      text-align: center;
    }

    .list {
      overflow: auto;
      display: block;
      width: 50vw;
      /* width */
      ::-webkit-scrollbar {
        width: 1px;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888888;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #1188ff;
      }
    }
  }
`;

export default Wrapper;
