import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: auto;
  /* display: none; */
  display: block;

  .loading {
    text-align: center;
  }

  .empty {
    color: #888888;
  }

  @media only screen and (min-width: 900px) {
    display: block;
    overflow: auto;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 1px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #1188ff;
  }
`;

export default Wrapper;
