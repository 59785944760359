import React from "react";
import { observer } from "mobx-react";
// components
import canalsStore from "../stores/CanalsStore";
import gamesStore from "../stores/GamesStore";
import LivecoveragesCol from "./LivecoveragesCol";
import MenuHeader from "./MenuHeader";
import Tab from "./Tab";
// styles
import { Wrapper } from "./Livecoverages.styles";

const Livecoverages: React.FC = observer(() => {
  const [activeElement, setActiveElement] = React.useState("gamesToday");

  React.useEffect(() => {
    canalsStore.loadCanals();
    gamesStore.loadGames();
  }, []);

  const activeHandler = (name: string) => {
    setActiveElement(name);
  };

  const list = [
    { name: "gamesToday", title: "Dzisiaj", data: gamesStore.games.gamesToday },
    {
      name: "gamesTomorrow",
      title: "Jutro",
      data: gamesStore.games.gamesTomorrow,
    },
    { name: "gamesWeek", title: "Tydzień", data: gamesStore.games.gamesWeek },
    { name: "gamesLater", title: "Później", data: gamesStore.games.gamesLater },
  ];

  const tabRender = () => {
    return (
      <div className="row tabs">
        {list.map((item) => {
          return (
            <Tab
              activeElement={activeElement}
              key={item.name}
              name={item.name}
              onClickHandler={activeHandler}
              title={item.title}
            />
          );
        })}
      </div>
    );
  };

  const livecoveragesColRender = () => {
    return (
      <div className="row data">
        {list.map((item) => {
          return (
            <LivecoveragesCol
              activeElement={activeElement}
              data={item.data}
              key={item.name}
              loading={gamesStore.games.loading}
              name={item.name}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Wrapper>
      <MenuHeader />
      {tabRender()}
      {livecoveragesColRender()}
    </Wrapper>
  );
});

export default Livecoverages;
