import { makeAutoObservable, runInAction } from "mobx";

export interface CanalObject {
  name: string;
}

export interface CanalsObject {
  canalsAll: CanalObject[];
}

export class CanalsStore {
  public canals: CanalsObject = {
    canalsAll: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  public loadCanals = async () => {
    const url: string = process.env.REACT_APP_CANALS_URL!;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).catch((err) => {
      throw err;
    });
    const resData = await response.json().catch((err) => {
      throw err;
    });
    runInAction(() => {
      this.canals.canalsAll = resData;
      localStorage.setItem("allCanals", JSON.stringify(resData));

      if (localStorage.getItem("filteredCanals") === null) {
        localStorage.setItem("filteredCanals", JSON.stringify(resData));
      }
    });
  };
}

const canalsStore = new CanalsStore();

export default canalsStore;
