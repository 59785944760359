import React from "react";
// styles
import { Wrapper } from "./Tab.styles";

type Props = {
  activeElement: string;
  name: string;
  // eslint-disable-next-line no-unused-vars
  onClickHandler: (name: string) => void;
  title: string;
};

const Tab: React.FC<Props> = ({
  name,
  title,
  activeElement,
  onClickHandler,
}: Props) => {
  return (
    <Wrapper activeTab={activeElement === name} onClick={() => {}}>
      <button
        type="button"
        onClick={() => onClickHandler(name)}
        className="title"
      >
        {title}
      </button>
    </Wrapper>
  );
};

export default Tab;
