import styled, { css } from "styled-components";

type Props = {
  activeTab: Boolean;
};

const colActive = css`
  display: flex;
`;

const colInactive = css`
  display: none;
`;

export const Wrapper = styled.div<Props>`
  display: flex;
  width: 100vw;
  padding: 10px;
  flex-direction: column;
  background-color: #3c3c3c;
  max-height: 100%;
  ${({ activeTab }) => (activeTab ? colActive : colInactive)}

  .col-active {
    display: flex;
  }

  .col-inactive {
    display: none;
  }

  @media only screen and (min-width: 900px) {
    width: 100vw;
    max-height: 100%;
    display: flex;
  }
`;

export default Wrapper;
