import React from "react";
import Switch from "react-switch";
// styles
import { Wrapper } from "./OptionsEntry.styles";

type Props = {
  checkedCanal: boolean;
  name: string;
  toogle: Function;
};

const OptionsEntry: React.FC<Props> = ({
  checkedCanal,
  name,
  toogle,
}: Props) => {
  return (
    <Wrapper>
      <p className="canalName">{name}</p>
      <Switch
        activeBoxShadow=""
        checked={checkedCanal}
        className="toggle"
        height={20}
        onChange={(e) => toogle(e)}
        onColor="#114477"
        onHandleColor="#1188FF"
        offColor="#aaaaaa"
        offHandleColor="#888888"
        width={40}
      />
    </Wrapper>
  );
};

export default OptionsEntry;
