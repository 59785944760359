import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato');

  body,
  html {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	background-color: #3c3c3c;
	font-family: 'Lato', sans-serif;
  }
`;

export default GlobalStyle;
