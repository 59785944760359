import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// components
import { GameObject } from "../stores/GamesStore";
// styles
import { Wrapper } from "./LivecoverageEntry.styles";

dayjs.extend(utc);

type Props = {
  data: GameObject;
};

const LivecoverageEntry: React.FC<Props> = ({ data }: Props) => {
  return (
    <Wrapper>
      <p>
        <span className="category">{data.category}</span>{" "}
        <span className="league">{data.league}</span>
      </p>
      <p className="game">{data.game}</p>
      <p className="tvdatetime">
        {dayjs(data.tvdatetime).utcOffset(0).format("ddd DD MMM, HH:mm")}
      </p>
      <p className="tvcanal">{data.tvcanal}</p>
    </Wrapper>
  );
};

export default LivecoverageEntry;
