import styled, { css } from "styled-components";

type Props = {
  activeTab: Boolean;
  onClick: any;
};

const tabActive = css`
  border-bottom: 2px solid #1188ff;
`;

const tabInactive = css`
  border-bottom: 2px solid transparent;
`;

export const Wrapper = styled.div<Props>`
  display: flex;
  width: 100vw;
  padding: 10px;
  flex-direction: column;
  background-color: #3c3c3c;
  max-height: 100%;
  ${({ activeTab }) => (activeTab ? tabActive : tabInactive)}

  .title {
    text-align: center;
    color: #1188ff;
    font-family: "Lato";
    font-size: 1.25em;
    text-transform: uppercase;
    margin: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 0;
    background-color: transparent;
  }

  .title:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 900px) {
    width: 100vw;
    max-height: 100%;
    border-bottom: 2px solid transparent;

    .title:hover {
      cursor: default;
    }
  }
`;

export default Wrapper;
